<template>
    <FormWrapper>
        <PageTitle title="Update On Duty Request" slot="title" link="/helpContent/Leave Approve" />
        <div v-html="userName" id="sub-heading"></div>
        <br />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-4">
                    <InputDate label="Start Date" v-model="form.startDate" :style="{pointerEvents: display}" /> 
                </div>
                <div class="col-sm-4">
                    <InputDate label="End Date" v-model="form.endDate" :style="{pointerEvents: display}" /> 
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <InputText label="Start Time" v-model="form.startTime" :style="{pointerEvents: display}" /> 
                </div>
                <div class="col-sm-4">
                    <InputText label="End Time" v-model="form.endTime" :style="{pointerEvents: display}" /> 
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <InputTextArea v-model="form.reason" label="Reason" :style="{pointerEvents: display}" />
                </div>
            </FormRow>
            <FormRow>
                <h4>Manager Comments</h4>
                <br/>
                <div class="col-sm-4">
                    <InputTextArea v-model="form.notes" label="Notes" />
                </div>
            </FormRow>
            <br>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6">
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="onAprroveOrReject(1)">Approve</FormButton>
                    <FormButton type="danger" @click="onAprroveOrReject(0)">Reject</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <!-- <FormRow>
            <div class="col-md-12">
                <h4>Leave Matrix</h4>
                <DataTable :actions="true"
                    :data="leaveMatrixData"
                    :columns="tableLeaveMatrix"
                    :is-loading="isLoading">
                </DataTable>
            </div>
        </FormRow> -->
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getUserNameById, leaveMatrix, odRequestList, changeOnDutyStatus} from '../api';
    import InputDate from "Components/form/InputDate";
    import tableLeaveMatrix from "./tableLeaveMatrix";
    // import DataTable from "Components/DataTable";
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "Update",
        mixins: [loadingMixin],
        components: {
        FormWrapper,
        PageTitle,
        // DataTable,
        InputDate,
        ValidationObserver
    },
        data() {
            return {
                showMessage:{
                    isVisible: false,
                    message: ''
                },
                form:{
                    startDate: '',
                    endDate:'',
                    startTime: '',
                    endTime: '',
                    reason: '',
                    notes:''
                },
                display:'none !important',
                userName: '',
                tableLeaveMatrix,
                leaveMatrixData: []
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getAllList()
            this.getLeaveRecord(),
            this.getDataByFilter()
        },
        methods: {
            getDataByFilter() {
                if(this.$route.params.id){
                    setTimeout(() => {
                        this.showLoader();
                        const filters = {};
                        filters.staffId = atob(this.$route.params.staffId);
                        filters.id = atob(this.$route.params.id);
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        odRequestList(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
            },
            onAprroveOrReject(val){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            staffId: this.$route.params.staffId?atob(this.$route.params.staffId):0,
                            staffOnDutyId: this.$route.params.id?atob(this.$route.params.id):0,
                            toDoId: this.$route.params.toDoId?atob(this.$route.params.toDoId):0,
                            approvalStatusId: (val===1)?86:88, //86 -> Approved & 88 -> Reject
                            approvalComment: this.form.notes
                        };
                        changeOnDutyStatus(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.startDate = response.data[0].OD_startdate;
                this.form.endDate = response.data[0].OD_enddate;
                this.form.startTime = response.data[0].StartTime;
                this.form.endTime = response.data[0].EndTime;
                this.form.reason = response.data[0].Reason;
            },
            getLeaveRecord() {
                if (this.$route.params.staffId) {
                    let data = {
                        staff_id: atob(this.$route.params.staffId),
                    };
                    // leaveMatrix(data).then((res) => {
                    //     this.leaveMatrixData = res.data;
                    // });
                }
            },
            async getAllList() {
                const filters = {};
                filters.userId = atob(this.$route.params.staffId);
                const data = {
                    filterjson: {
                        filter: [filters]
                    }
                };
                await getUserNameById(data).then((res)=>{
                    this.userName = res.data[0].name;
                });
            },
        }
    } 
</script>
<style lang="scss">
    #sub-heading{
        font-weight: 900;
        float: right;
    }

    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
        textarea{
        height: 110px !important;
    }
</style>